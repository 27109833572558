var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-eticket-refund-ticket-vn1a",
      "title": "Tính giá hoàn vé",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "body-class": "p-50",
      "size": "lg"
    },
    on: {
      "show": _vm.showModalRefundTicket
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": "",
            "disabled": _vm.isEmpty(_vm.indexSelectedTicketToRefund)
          },
          on: {
            "click": _vm.submitCheckPriceAmount
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.CALCULATE_PRICE')))])])];
      }
    }])
  }, [_c('b-card', {
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "custom-label-checkbox"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm.listPaxsData.length > 1 ? _c('b-form-checkbox', {
          staticClass: "py-0 pt-1",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2 font-weight-bold",
          staticStyle: {
            "padding-bottom": "13px"
          }
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.indexSelectedTicketToRefund,
            callback: function callback($$v) {
              _vm.indexSelectedTicketToRefund = $$v;
            },
            expression: "indexSelectedTicketToRefund"
          }
        }, _vm._l(_vm.listPaxsData, function (ticket, index) {
          return _c('b-form-checkbox', {
            key: index,
            staticClass: "mb-50  ml-50",
            attrs: {
              "value": ticket
            },
            on: {
              "change": function change($event) {
                return _vm.selectTicketHandle(ticket);
              }
            }
          }, [_c('div', {
            staticClass: "d-flex flex-wrap gap-1 align-items-center"
          }, [_c('span', {
            staticClass: "text-warning fw-600"
          }, [_vm._v(_vm._s("".concat(ticket.number, " ")))]), _c('small', {
            staticClass: "mr-25 fw-600"
          }, [_vm._v("(" + _vm._s(ticket.paxId) + ")")]), _c('span', {
            staticClass: "mr-50 fw-600"
          }, [_vm._v(_vm._s("".concat(ticket.lastName, "/").concat(ticket.firstName)))]), _c('span', {
            staticClass: "mr-50 text-dark font-weight-bold"
          }, [_vm._v(_vm._s(_vm.resolveSegmentsInEticket(ticket.segments)))]), _c('b-badge', {
            staticClass: "px-50 ml-50 rounded-lg fw-700",
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(" " + _vm._s(ticket.ticketType) + " ")]), _c('span', {
            class: "ml-25 fw-600 ".concat(['OK', 'OPEN', 'EMD'].includes(ticket.status) ? 'text-success' : 'text-warning')
          }, [_vm._v(" " + _vm._s(ticket.status) + " ")])], 1)]);
        }), 1)];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "mt-1 shadow"
  }, [_c('b-alert', {
    staticClass: "mb-50 p-50 fw-700",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Lưu ý: "), _c('br'), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('span', [_vm._v(" - Vé đa hành trình/nhiều chặng, vé khứ hồi đã sử dụng một chặng cần hoàn, LH Booker hỗ trợ! ")]) : _vm._e(), _vm._v(" "), _c('br'), _c('span', [_vm._v(" - Bạn phải chắc chắn nắm được điều kiện hoàn vé. Các trường hợp không chắc chắn vui lòng liên hệ Booker. ")]), _vm._v(" "), _c('br'), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('span', [_vm._v(" - Nếu hoàn vé người lớn có em bé đi cùng sẽ bắt buộc hoàn cả vé em bé. "), _c('br'), _vm._v(" - Số vé hoàn sẽ không void được. ")]) : _vm._e()])])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-modify-eticket-refund-ticket-price-vn1a",
      "title": _vm.$t('reservation.refundTicket'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var close = _ref3.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.isConfirm
          },
          on: {
            "click": _vm.submitRefundTicket
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.refundTicket')) + " ")])])];
      }
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "spinner-variant": "info",
      "opacity": "0.85"
    }
  }, [_vm.resRefundAmount ? _c('b-table', {
    attrs: {
      "items": _vm.resRefundAmount.eticketsRefund,
      "fields": _vm.tableRefundAmountColumns,
      "hover": "",
      "small": "",
      "responsive": "",
      "outlined": "",
      "thead-class": "text-nowrap text-center",
      "tbody-class": "text-nowrap text-center fw-700",
      "show-empty": "",
      "no-border-collapse": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableRefundAmountColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.historyActions.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.name) || '-'))])];
      }
    }, {
      key: "cell(segments)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.segments.map(function (segment) {
          return segment !== null && segment !== void 0 ? segment : '-';
        }).join(', ')) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.total)))])];
      }
    }, {
      key: "cell(penalty)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "text-danger fw-700"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.penalty)))])];
      }
    }, {
      key: "cell(totalRefund)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "text-warning fw-700"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.totalRefund)))])];
      }
    }], null, true)
  }) : _vm._e()], 1), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 1), _c('ModalVoidTicketCancelAlert', {
    attrs: {
      "title": 'Hoàn vé'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }